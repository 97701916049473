<template>
  <div>
    <template v-if="hasCall">
      <CallToAction />
    </template>
    <footer>
      <div class="container" :class="{hasCall}">
        <div class="content">
          <div class="info">
            <img class="logo" src="@/assets/logo-white.png" alt="Logomarca da LiberFly">
            <div class="description">
              A LiberFly é uma empresa que promove soluções entre passageiros e companhias aéreas. Fundada em 2016, defendemos os seus direitos na Europa e no mundo, segundo a legislação em vigor, como é o caso do regulamento da UE EC261.
            </div>
            <div class="social">
              <a rel="noopener" target="_blank" href="https://www.linkedin.com/company/liberfly/">
                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
              </a>
              <a rel="noopener" target="_blank" href="https://www.facebook.com/liberflypt/">
                <font-awesome-icon :icon="['fab', 'facebook-f']" />
              </a>
              <a rel="noopener" target="_blank" href="https://www.instagram.com/liberflypt/">
                <font-awesome-icon :icon="['fab', 'instagram']" />
              </a>
              <a rel="noopener" target="_blank" href="https://twitter.com/liberflypt">
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import CallToAction from "@/components/CallToAction.vue";

export default {
  props: {
    hasCall: {
      default: true,
    },
  },
  components: {
    CallToAction,
  },
};
</script>

<style lang="scss">
footer {
  .container {
    padding-top: 50px;

    .content {
      max-width: 500px;
      margin: 0 auto;
    }

    &.hasCall {
      border-top: 1px solid $grey-primary;
    }
  }

  .info {
    color: $white-secondary;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      display: block;
      width: 153px;
      height: auto;
      margin-bottom: 9px;
    }

    .description {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-top: 10px;
      margin-bottom: 43px;
      text-align: center;
    }

    .social {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 200px;

      a:hover {
        svg {
          color: $grey-primary;
        }
      }

      svg {
        color: $white-secondary;
        height: 30px;
        width: auto;
      }
    }
  }
}
</style>
